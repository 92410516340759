import React from 'react'
import { IProcessSectionRecord } from '@/lib/global.types'
import { Image as DatoImage } from 'react-datocms'
import { AtomButton } from '@/components/atoms/AtomButton'
import { motion, Variants } from 'framer-motion'
import { headerVariants, listItemVariants } from '@/components/helpers/Animations'

const imageVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.2,
    },
  },
}

const OrganismProcessSection = (props: IProcessSectionRecord) => {
  return (
    <section className="section-space-base container mx-auto px-24">
      <motion.h2
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="header-2 header-space-base lg:text-center text-secondary-dark"
      >
        <motion.span className="block" variants={headerVariants}>
          {props.title}
        </motion.span>
      </motion.h2>
      <div className="flex justify-between space-x-24 sm:space-x-32 md:space-x-64 xl:space-x-120">
        <ul className="w-1/2 2xl:w-1/3 space-y-28 my-12 md:py-24 sm:space-y-32 md:space-y-40 lg:space-y-56 xl:space-y-64 2xl:space-y-72 overflow-hidden">
          {props.processItems.map((item, index) => (
            <motion.li
              key={item.id}
              custom={index}
              initial="hidden"
              whileInView="visible"
              variants={listItemVariants}
              className="overflow-hidden"
            >
              <span className="hidden lg:block text-secondary-light text-3 font-bold font-sans underline mb-16 xl:mb-24">
                {item.label}
              </span>
              <h3 className="header-3 header-space-sm text-secondary-dark">{item.title}</h3>
              <p className="text-3 text-secondary">{item.description}</p>
            </motion.li>
          ))}
          <li>
            <AtomButton
              title={props.link[0]?.page?.title || ''}
              href={`/${props.link[0]?.page?.slug}` || ''}
              label={props.link[0]?.label || ''}
              variant="primary"
            />
          </li>
        </ul>
        {props.image && (
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
            variants={imageVariants}
            className="relative flex w-1/2 rounded-l-xl md:rounded-lg translate-x-24 md:translate-x-0 overflow-hidden bg-primary"
          >
            <DatoImage
              objectFit="cover"
              className="h-full object-cover"
              data={{ ...props.image.responsiveImage, alt: props.image.alt }}
              objectPosition="50% 50%"
            />
          </motion.div>
        )}
      </div>
    </section>
  )
}

export default OrganismProcessSection
