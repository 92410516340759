import React from 'react'
import { ITextSectionRecord } from '@/lib/global.types'
import { headerVariants, textVariants } from '@/components/helpers/Animations'
import { motion } from 'framer-motion'
import Markdown from '@/components/helpers/Markdown'

const OrganismTextSection = (props: ITextSectionRecord) => {
  return (
    <section className="section-space-base container mx-auto px-24 flex flex-col sm:flex-row sm:space-x-56 md:space-x-72 lg:space-x-96 xl:space-x-120 2xl:space-x-[140px]">
      <motion.h2
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        className="header-2 header-space-base text-secondary-dark"
      >
        <motion.span className="block" variants={headerVariants}>
          {props.title}
        </motion.span>
      </motion.h2>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        className="text-2 text-secondary"
      >
        <motion.span className="block" variants={textVariants}>
          <Markdown markdown={props.content} />
        </motion.span>
      </motion.div>
    </section>
  )
}

export default OrganismTextSection
