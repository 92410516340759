import React from 'react'
import OrganismPageHeader from '@/components/organisms/OrganismPageHeader'
import { IContent } from '@/lib/global.types'
import OrganismProjectsList from '@/components/organisms/OrganismProjectsList'
import OrganismProcessSection from '@/components/organisms/OrganismProcessSection'
import OrganismTestimonialSection from '@/components/organisms/OrganismTestimonialSection'
import OrganismTextSection from '@/components/organisms/OrganismTextSection'
import OrganismCtaSection from '@/components/organisms/OrganismCtaSection'
import OrganismProcessAboutSection from '@/components/organisms/OrganismProcessAboutSection'
import OrganismContactSection from '@/components/organisms/OrganismContactSection'
import OrganismPictureSection from '@/components/organisms/OrganismPictureSection'

interface IComponents {
  [key: string]: any
}

const COMPONENTS: IComponents = {
  PageHeaderRecord: OrganismPageHeader,
  ProjectsListRecord: OrganismProjectsList,
  ProcessSectionRecord: OrganismProcessSection,
  ProcessSectionAboutRecord: OrganismProcessAboutSection,
  TestimonialSectionRecord: OrganismTestimonialSection,
  TextSectionRecord: OrganismTextSection,
  CtaSectionRecord: OrganismCtaSection,
  ContactSectionRecord: OrganismContactSection,
  PictureSectionRecord: OrganismPictureSection,
}

const PageContent = (block: IContent) => {
  if (typeof COMPONENTS[block.__typename] !== 'undefined') {
    return React.createElement(COMPONENTS[block.__typename], {
      key: block.id,
      ...block,
    })
  }
  return React.createElement(() => <div>The component {block.__typename} has not been created yet.</div>, {
    key: block.id,
  })
}

export default PageContent
