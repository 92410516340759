import { ReactNode } from 'react'
import { motion } from 'framer-motion'
import { IMenuRecord } from '@/lib/global.types'
import OrganismFooter from '@/components/organisms/OrganismFooter'
import OrganismHeader from '@/components/organisms/OrganismHeader'

interface ILayoutProps {
  children: ReactNode
  mainMenu: IMenuRecord
  footerMenu: IMenuRecord
  socialMediaMenu: IMenuRecord
}

export default function Layout({ children, mainMenu, footerMenu, socialMediaMenu }: ILayoutProps) {
  return (
    <>
      <OrganismHeader menu={mainMenu} socialMenu={socialMediaMenu.menu} />
      <motion.div
        className="relative overflow-hidden"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        {children}
      </motion.div>
      <OrganismFooter menu={footerMenu.menu} />
    </>
  )
}
