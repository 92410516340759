import React from 'react'
import { IPictureSectionRecord } from '@/lib/global.types'
import { Image as DatoImage } from 'react-datocms'
import { Swiper, SwiperSlide } from 'swiper/react'
import { headerVariants, textVariants } from '@/components/helpers/Animations'
import { motion } from 'framer-motion'

const OrganismPictureSection = (props: IPictureSectionRecord) => {
  return (
    <section className="section-space-base container mx-auto px-24">
      <motion.h2
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        className="header-2 header-space-md text-secondary-dark overflow-hidden"
      >
        <motion.span className="block" variants={headerVariants}>
          {props.title}
        </motion.span>
      </motion.h2>
      <motion.p
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        className="text-2 text-secondary max-w-4xl header-space-base"
      >
        <motion.span className="block" variants={textVariants}>
          {props.description}
        </motion.span>
      </motion.p>

      <Swiper
        className="picture-swiper"
        slidesPerView={'auto'}
        spaceBetween={24}
        breakpoints={{
          1024: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
          1280: {
            slidesPerView: 3,
          },
        }}
      >
        {props.gallery.map((item, index) => (
          <SwiperSlide key={index}>
            <DatoImage className="rounded-lg select-none" data={{ ...item.responsiveImage, alt: item.alt }} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export default OrganismPictureSection
