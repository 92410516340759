import React from 'react'
import { Image as DatoImage } from 'react-datocms'
import { ITestimonial } from '@/lib/global.types'

interface IMoleculeTestimonialItem {
  testimonial: ITestimonial
  isActive: boolean
}

const MoleculeTestimonialItem = ({ testimonial, isActive }: IMoleculeTestimonialItem) => {
  const { person, reference } = testimonial
  return (
    <div className="relative overflow-hidden group transition-all aspect-[59/80] lg:aspect-[17/33] lg:hover:-translate-y-40">
      {person.company && (
        <div className="w-[80px] lg:w-[120px] absolute top-24 left-24 xl:left-36 xl:top-36 z-10 grayscale">
          <DatoImage
            data={{ ...person.company?.logotype?.responsiveImage, alt: person.company?.name || '' }}
            objectFit="contain"
            className="lg:opacity-50 lg:group-hover:opacity-100"
          />
        </div>
      )}
      <DatoImage
        objectFit="cover"
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-contain min-h-full min-w-full -z-20"
        data={{
          ...person.image.responsiveImage,
          alt: person.image.alt,
        }}
      />
      <span
        className={`bg-gradient-to-b from-[#00000066] to-[#000] -z-10 absolute inset-0 transition-opacity swiper-slide-overflow lg:group-hover:opacity-50 ${
          isActive ? 'opacity-50' : ''
        }`}
      />
      <figure className={`absolute bottom-0 left-0 right-0 space-y-12 xl:space-y-24 px-24 py-20 xl:px-36 xl:py-32`}>
        <blockquote className="italic font-body font-light text-[12px] leading-[16px] lg:text-[16px] lg:leading-[24px]">
          {reference}
        </blockquote>
        <figcaption className="figure-caption-1 space-y-4 xl:space-y-8">
          <span>
            {person.firstName} {person.lastName}
          </span>
          <cite className="block cite">{person.function}</cite>
        </figcaption>
      </figure>
    </div>
  )
}

export default MoleculeTestimonialItem
