import React from 'react'
import { IMenu } from '@/lib/global.types'
import Image from 'next/image'
import Link from 'next/link'
import { AtomButton } from '@/components/atoms/AtomButton'

interface IOrganismFooter {
  menu: IMenu[]
}

const OrganismFooter = ({ menu }: IOrganismFooter) => {
  return (
    <footer className="section-space-base container p-24 md:py-48 xl:py-64 mx-auto flex justify-between items-center space-x-40">
      <ul className="flex space-x-32 md:space-x-52">
        {menu.map((item) => (
          <li key={item.id} className="whitespace-nowrap">
            <AtomButton
              title={item.title || ''}
              href={`/${item.page?.slug}` || '/'}
              label={item.label}
              variant="secondary"
            />
          </li>
        ))}
      </ul>
      <Link href="/">
        <a className="block w-[66px] h-[26.32px] md:w-80 md:h-[31.90px] xl:w-[152px] xl:h-[28px]">
          <Image src="/logo-light.svg" width={152} height={29} alt="Logo" />
        </a>
      </Link>
    </footer>
  )
}

export default OrganismFooter
