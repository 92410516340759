import React from 'react'
import { AtomButton } from '@/components/atoms/AtomButton'
import MoleculeContactForm from '@/components/molecules/MoleculeContactForm'
import { IContactSectionRecord } from '@/lib/global.types'
import Markdown from '@/components/helpers/Markdown'
import { headerVariants, textVariants } from '@/components/helpers/Animations'
import { motion } from 'framer-motion'

const OrganismContactSection = (props: IContactSectionRecord) => {
  return (
    <section className="section-space-base container mx-auto px-24 flex flex-col lg:flex-row lg:justify-between space-y-40 md:space-y-64 lg:space-y-0 lg:space-x-56 xl:space-x-96 2xl:space-x-120">
      <div className="space-y-32 lg:space-y-96 lg:w-5/12 xl:w-2/6 2xl:w-3/12">
        <div>
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.8 }}
            className="header-2 text-secondary-dark header-space-md overflow-hidden"
          >
            <motion.span className="block" variants={headerVariants}>
              {props.title}
            </motion.span>
          </motion.h2>
          <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.8 }}>
            <motion.div variants={textVariants}>
              <Markdown markdown={props.description} />
            </motion.div>
          </motion.div>
        </div>

        <div>
          <h3 className="header-5 text-secondary-dark mb-36">{props.ctaLabel}</h3>
          <ul className="grid grid-cols-3 gap-16 md:gap-32 xl:gap-40">
            {props.menu.menu.map((item) => (
              <li key={item.id}>
                <AtomButton
                  title={item.label}
                  href={item.url || '/'}
                  label={item.label}
                  target="_blank"
                  rel="noopener"
                  variant="secondary"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex-1">
        <MoleculeContactForm {...props.contactForm[0]} />
      </div>
    </section>
  )
}

export default OrganismContactSection
