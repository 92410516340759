import React from 'react'
import { ITestimonialSectionRecord } from '@/lib/global.types'
import MoleculeTestimonials from '@/components/molecules/MoleculeTestimonials'
import { textVariants } from '@/components/helpers/Animations'
import { motion } from 'framer-motion'

const OrganismTestimonialSection = (props: ITestimonialSectionRecord) => {
  return (
    <section className="testimonial-section section-space-base custom-gradient text-primary-light pt-40 sm:pt-64 md:pt-72 lg:pt-80 xl:pt-96 2xl:pt-[140px]">
      <div className="container mx-auto px-24">
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.8 }}
          className="header-2 lg:text-center mb-32 sm:mb-40 md:mb-48 lg:mb-56 xl:mb-64 2xl:mb-92"
        >
          <motion.span className="block" variants={textVariants}>
            {props.title}
          </motion.span>
        </motion.h2>
      </div>
      <MoleculeTestimonials testimonials={props.testimonials} />
    </section>
  )
}

export default OrganismTestimonialSection
