import React, { useCallback } from 'react'
import { useRouter } from 'next/router'

const MoleculeLanguageSwitcher = () => {
  const router = useRouter()
  const locales = router.locales
  const currentLocale = router.locale

  const switchLocale = useCallback(
    (locale: string) => {
      const path = router.asPath

      return router.push('/', '/', { locale })
    },
    [router.locale],
  )

  if (locales?.length) {
    return (
      <ul className="flex space-x-24">
        {locales.map((locale) => (
          <li key={locale} className={currentLocale === locale ? 'text-primary-light' : 'font-normal text-disabled'}>
            <button
              className="uppercase text-[20px] leading-[22px]"
              type={'button'}
              onClick={() => switchLocale(locale)}
            >
              {locale}
            </button>
          </li>
        ))}
      </ul>
    )
  }

  return null
}

export default MoleculeLanguageSwitcher
