import React from 'react'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { IMenu } from '@/lib/global.types'
import { useRouter } from 'next/router'

interface IAtomMenuItem {
  variant: 'large' | 'small'
  click: () => void
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const classes = {
  large:
    'block text-[32px] sm:text-[40px] md:text-[48px] lg:text-[56px] xl:text-[64px] 2xl:text-[72px] px-24 py-8 text-primary-light',
  small: 'block text-[14px] xl:text-[20px] text-secondary-light mx-24',
}

export const AtomMenuItem = (props: IMenu & IAtomMenuItem) => {
  const { locale } = useRouter()
  return (
    <motion.li className="my-4" variants={variants}>
      <Link href={props.url ?? `/${props.page?.slug}`} locale={locale}>
        <a
          className={`btn ${classes[props.variant]}`}
          title={props.title}
          target={props.url ? '_blank' : '_self'}
          onClick={() => props.click()}
        >
          {props.label}
        </a>
      </Link>
    </motion.li>
  )
}
