import React from 'react'
import { motion, Variants } from 'framer-motion'
import { AtomMenuItem } from '@/components/atoms/AtomMenuItem'
import { IMenu, IMenuRecord } from '@/lib/global.types'
import MoleculeLanguageSwitcher from '@/components/molecules/MoleculeLanguageSwitcher'

interface IMoleculeNavigation {
  menu: IMenuRecord
  socialMenu: IMenu[]
  toggle: () => void
}

const variants: Variants = {
  open: {
    opacity: 1,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    transitionEnd: { display: 'block' },
  },
  closed: {
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
    transitionEnd: { display: 'none' },
  },
}

export const MoleculeNavigation = ({ menu, socialMenu, toggle }: IMoleculeNavigation) => (
  <motion.nav className="fixed top-80 left-0 right-0 bottom-0 z-40" variants={variants}>
    <div className="flex flex-col items-center w-full h-full text-center p-24 sm:p-32 md:p-40 lg:p-48 xl:p-56 2xl:p-72 space-y-24 text-primary-light overflow-auto">
      <ul className="flex flex-1 justify-center flex-col items-center w-full">
        <div className="mb-56">
          <MoleculeLanguageSwitcher />
        </div>
        {menu.menu.map((item) => (
          <AtomMenuItem key={item.id} variant="large" {...item} click={toggle} />
        ))}
        <li className="mt-32">
          {menu.ctaLinkLabel && menu.ctaLinkTitle && menu.ctaLinkUrl && (
            <a
              href={menu.ctaLinkUrl}
              title={menu.ctaLinkTitle}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary-light font-bold bg-primary py-16 px-12 rounded-[4px]"
            >
              {menu.ctaLinkLabel}
            </a>
          )}
        </li>
      </ul>
      <ul className="flex flex-wrap justify-center">
        {socialMenu.map((item) => (
          <AtomMenuItem key={item.id} variant="small" {...item} click={toggle} />
        ))}
      </ul>
    </div>
  </motion.nav>
)
