import React from 'react'
import { IProject } from '@/lib/global.types'
import { Image as DatoImage } from 'react-datocms'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { listItemVariants } from '@/components/helpers/Animations'

const MoleculeProject = (props: IProject) => {
  return (
    <motion.div variants={listItemVariants} className="space-y-12">
      <Link href={props.url}>
        <a
          className="block aspect-square rounded-xl overflow-hidden bg-primary-light"
          title={props.name}
          target="_blank"
          rel="noopener"
        >
          <DatoImage
            objectFit="cover"
            className="h-full w-full transition-transform duration-500 group-hover:scale-105"
            data={{ ...props.image?.responsiveImage, alt: props.image?.alt }}
          />
        </a>
      </Link>

      <div>
        <h3 className="header-3 text-secondary-dark header-space-sm">{props.name}</h3>
        <p className="text-3 text-secondary">{props.description}</p>
      </div>
    </motion.div>
  )
}

export default MoleculeProject
