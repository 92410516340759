import { Variants } from 'framer-motion'

export const headerVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 50,
    x: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      ease: 'circIn',
      // duration: 0.6,
      bounce: 0.2,
    },
  },
}

export const textVariants: Variants = {
  hidden: {
    opacity: 0,
    x: 50,
    scale: 0.9,
  },
  visible: {
    opacity: 1,
    scale: 1,
    x: 0,
    transition: {
      ease: 'circIn',
    },
  },
}

export const buttonVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.9,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: 'circIn',
      delay: 0.2,
    },
  },
}

export const listItemVariants: Variants = {
  hidden: {
    y: 100,
    opacity: 0.0,
  },
  visible: (index = 1) => ({
    opacity: 1,
    y: 0,
    transition: {
      ease: 'linear',
      delay: index * 0.2,
    },
  }),
}
