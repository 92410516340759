import React from 'react'
import { IProjectsListRecord } from '@/lib/global.types'
import MoleculeProject from '@/components/molecules/MoleculeProject'
import { AtomButton } from '@/components/atoms/AtomButton'
import { motion } from 'framer-motion'
import { headerVariants } from '@/components/helpers/Animations'

const ProjectsList = (props: IProjectsListRecord) => {
  return (
    <section className="section-space-base container mx-auto px-24 flex flex-col items-center overflow-hidden">
      {props.title && (
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="header-2 text-secondary-dark header-space-base whitespace-pre-wrap w-full"
        >
          <motion.span className="block" variants={headerVariants}>
            {props.title}
          </motion.span>
        </motion.h2>
      )}

      <motion.ul className="grid grid-cols-2 gap-24 lg:gap-92 mt-24 lg:mt-92">
        {props.projects.map((item) => (
          <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            key={item.id}
            className="block even:-mt-24 lg:even:-mt-92 group transition-transform duration-500 hover:scale-95 overflow-hidden"
          >
            <MoleculeProject {...item} />
          </motion.li>
        ))}
      </motion.ul>

      {props.link && props.link[0]?.page && (
        <div className="mt-32 sm:mt-40 md:mt-56 lg:mt-64 xl:mt-80 2xl:mt-96">
          <AtomButton
            title={props.link[0].page.title}
            href={`/${props.link[0].page.slug}`}
            label={props.link[0].label}
          />
        </div>
      )}

      {props.link && props.link[0]?.url && (
        <div className="mt-32 sm:mt-40 md:mt-56 lg:mt-64 xl:mt-80 2xl:mt-96">
          <AtomButton
            title={props.link[0].title}
            href={props.link[0].url}
            target="_blank"
            label={props.link[0].label}
          />
        </div>
      )}
    </section>
  )
}

export default ProjectsList
