import React, { useState } from 'react'
import { FormikHelpers, useFormik } from 'formik'
import * as yup from 'yup'
import { Alert, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from '@mui/material'
import { IContactForm } from '@/lib/global.types'
import { useRouter } from 'next/router'

interface IValues {
  name: string
  email: string
  description: string
  acceptRules: boolean
}

const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY

const MoleculeContactForm = (props: IContactForm) => {
  const router = useRouter()
  const [result, setResult] = useState('')
  const [errors, setErrors] = useState('')

  const validationSchema = yup.object({
    name: yup.string().required(props.nameFieldRequiredError),
    email: yup.string().email(props.emailFieldIncorrectError).required(props.emailFieldRequiredError),
    description: yup.string().required(props.descriptionFieldRequiredError),
    acceptRules: yup.bool().oneOf([true], `${props.acceptRulesFieldRequiredError}`),
  })

  const formik = useFormik({
    initialValues: { name: '', email: '', description: '', acceptRules: false },
    validationSchema: validationSchema,
    onSubmit: (values: IValues, { setSubmitting }: FormikHelpers<IValues>) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(async (token: string) => {
          try {
            const response = await fetch('/api/contact', {
              method: 'POST',
              headers: { 'content-type': 'application/json' },
              body: JSON.stringify({ recaptchaResponse: token, ...values, locale: router.locale }),
            })

            if (response.ok) {
              setResult(response.statusText)
            } else {
              setErrors(response.statusText)
            }
          } catch (error) {
            setErrors('Bad Request')
          }

          setSubmitting(false)
        })
      })
    },
  })

  return (
    <div className={`xl:max-w-3xl ${formik.isSubmitting ? 'opacity-90 animate-pulse' : ''}`}>
      <form
        className="space-y-32 lg:space-y-40 xl:space-y-56"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <TextField
          fullWidth
          id="name"
          name="name"
          label={props.nameFieldLabel}
          variant="standard"
          className="custom-text-field"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <TextField
          fullWidth
          id="email"
          name="email"
          type="email"
          label={props.emailFieldLabel}
          variant="standard"
          className="custom-text-field"
          value={formik.values.email.toLowerCase()}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="description"
          name="description"
          label={props.descriptionFieldLabel}
          variant="standard"
          className="custom-text-field"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          multiline
        />

        <FormControl
          className="custom-text-field"
          required
          error={formik.touched.acceptRules && Boolean(formik.errors.acceptRules)}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.acceptRules}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="acceptRules"
              />
            }
            label={props.acceptRulesFieldLabel}
          />
          {formik.touched.acceptRules && Boolean(formik.errors.acceptRules) && (
            <FormHelperText>{formik.errors.acceptRules}</FormHelperText>
          )}
        </FormControl>

        <div className="text-secondary-dark text-3">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>

        {result && <Alert severity="success">Success!</Alert>}
        {errors && <Alert severity="error">{errors}</Alert>}

        <button
          className="btn block text-primary underline text-3 text-primary underline-offset-1"
          type="submit"
          disabled={!formik.isValid}
          title={props.submitButtonLabel}
        >
          {props.submitButtonLabel}
        </button>
      </form>
    </div>
  )
}

export default MoleculeContactForm
