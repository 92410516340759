import React, { useRef, useState } from 'react'
import { ITestimonial } from '@/lib/global.types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CgArrowLeft, CgArrowRight } from 'react-icons/cg'
import MoleculeTestimonialItem from '@/components/molecules/MoleculeTestimonialItem'

interface IMoleculeTestimonials {
  testimonials: ITestimonial[]
}

const MoleculeTestimonials = (props: IMoleculeTestimonials) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <div className="flex lg:container lg:mx-auto lg:px-24 relative z-20 w-full">
      <Swiper
        className="testimonials-swiper"
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper: any) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current
          swiper.params.navigation.nextEl = navigationNextRef.current
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        watchSlidesProgress={true}
        slidesPerView={'auto'}
        autoplay={false}
        loop={true}
        breakpoints={{
          1280: {
            slidesPerView: 3,
          },
          1536: {
            slidesPerView: 4,
          },
        }}
      >
        {props.testimonials.map((item, index) => (
          <SwiperSlide
            key={item.id}
            className="max-w-[290px] sm:max-w-[320px] md:max-w-[360px] lg:max-w-[400px] xl:max-w-none"
          >
            <MoleculeTestimonialItem testimonial={item} isActive={index === activeIndex} />
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        type="button"
        className="custom-pagination-btn left-24 lg:left-4 xl:left-0"
        aria-label="Left arrow"
        title="Left arrow"
        ref={navigationPrevRef}
      >
        <CgArrowLeft className="w-12 lg:w-20" />
      </button>
      <button
        type="button"
        className="custom-pagination-btn right-24 lg:right-4 xl:right-0"
        aria-label="Right arrow"
        title="Right arrow"
        ref={navigationNextRef}
      >
        <CgArrowRight className="w-12 lg:w-20" />
      </button>
    </div>
  )
}

export default MoleculeTestimonials
