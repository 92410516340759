import React from 'react'
import { AtomButton } from '@/components/atoms/AtomButton'
import { ICtaSectionRecord } from '@/lib/global.types'
import { motion } from 'framer-motion'
import { headerVariants } from '@/components/helpers/Animations'

const OrganismCtaSection = (props: ICtaSectionRecord) => {
  return (
    <section className="bg-primary-light mt-40 sm:mt-64 md:mt-120 xl:mt-[180px] 2xl:mt-[252px]">
      <div className="container mx-auto px-24 flex flex-col lg:flex-row space-y-32 lg:space-y-0 lg:space-x-96 xl:space-x-120 lg:items-center ">
        <h2 className="header-4 text-secondary-dark">
          <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.8 }}>
            <motion.span className="block" variants={headerVariants}>
              {props.title}
            </motion.span>
          </motion.div>
          <AtomButton
            title={props.link[0].page ? props.link[0].page?.title : props.link[0].title || ''}
            href={props.link[0].page ? `/${props.link[0].page?.slug}` : props.link[0].url || '/'}
            label={props.link[0].label}
            variant="underline"
          />
        </h2>

        <ul className="grid grid-cols-3 gap-16 md:gap-32 xl:gap-40">
          {props.menu.menu.map((item) => (
            <li key={item.id}>
              <AtomButton
                title={item.title || ''}
                href={item.url || ''}
                label={item.label}
                target="_blank"
                rel="noopener"
                variant="secondary"
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default OrganismCtaSection
