import React from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { buttonVariants } from '@/components/helpers/Animations'

interface IAtomButton {
  title: string
  href: string
  target?: '_blank' | '_self'
  label: string
  rel?: string
  variant?: 'primary' | 'secondary' | 'underline' | 'secondary-underline'
}

const variants = {
  primary: 'text-3 text-primary underline underline-offset-1',
  secondary: 'text-3 text-secondary-dark underline-offset-1',
  underline: 'text-primary font-light underline underline-offset-8 decoration-1',
  'secondary-underline': 'text-primary-dark underline underline-offset-1 text-3',
}

export const AtomButton = ({ href, title, target = '_self', label, rel, variant = 'primary' }: IAtomButton) => {
  return (
    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.8 }}>
      <Link href={href}>
        <a
          className={`btn ${variants[variant]} font-sans hover:text-secondary`}
          title={title}
          target={target}
          rel={rel}
        >
          <motion.span variants={buttonVariants} className="block">
            {label}
          </motion.span>
        </a>
      </Link>
    </motion.div>
  )
}
