import React, { useEffect, useRef } from 'react'
import { IMenu, IMenuRecord } from '@/lib/global.types'
import { motion, useCycle, Variants } from 'framer-motion'
import Image from 'next/image'
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
import { CgClose } from 'react-icons/cg'
import Link from 'next/link'
import { useDimensions } from '@/components/hooks/useDimensions'
import { MoleculeNavigation } from '@/components/molecules/MoleculeNavigation'
import useWindowDimensions from '@/components/hooks/useWindowDimensions'
import MoleculeLanguageSwitcher from '@/components/molecules/MoleculeLanguageSwitcher'

interface IOrganismHeader {
  menu: IMenuRecord
  socialMenu: IMenu[]
}

const sidebar: Variants = {
  open: ({ height }) => ({
    clipPath: `circle(${height * 2 + 200}px at 20px 20px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0px at 0px 0px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

const OrganismHeader = (props: IOrganismHeader) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)
  const windowDimensions = useWindowDimensions()

  useEffect(() => {
    document.body.style.position = isOpen ? 'fixed' : 'unset'
  }, [isOpen])

  return (
    <motion.header
      className="absolute flex items-center justify-between top-0 left-0 right-0 pt-24 sm:pt-40 md:pt-48 lg:pt-56 xl:pt-64 2xl:pt-72 z-20"
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
    >
      <div className="container px-24 mx-auto flex justify-between">
        <motion.div
          className="fixed inset-0 custom-gradient"
          custom={{ height: windowDimensions.height }}
          variants={sidebar}
        />
        <Link href="/">
          <a className="block w-72 h-[28.72px] md:w-120 md:h-[47.87px] xl:w-[177.165px] xl:h-[70.665px]">
            <Image src="/logo.svg" width={177.165} height={70.665} alt="Logo" />
          </a>
        </Link>
        <MoleculeNavigation {...props} toggle={toggleOpen} />
        <div className="flex items-center space-x-32">
          <div className="hidden lg:block pr-24">
            <MoleculeLanguageSwitcher />
          </div>
          {props.menu.ctaLinkUrl && props.menu.ctaLinkLabel && props.menu.ctaLinkTitle && (
            <a
              href={props.menu.ctaLinkUrl}
              title={props.menu.ctaLinkTitle}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary-light font-bold bg-primary py-16 px-12 rounded-[4px] hidden md:block"
            >
              {props.menu.ctaLinkLabel}
            </a>
          )}
          <button
            className="flex items-center space-x-4 md:space-x-8 xl:space-x-12 text-primary-light z-40"
            onClick={() => toggleOpen()}
          >
            <span className="text-3 font-sans">Menu</span>
            {!isOpen ? (
              <HiOutlineMenuAlt1 className="w-24 h-20 lg:w-32 lg:h-[26px]" />
            ) : (
              <CgClose className="w-24 h-24 lg:w-32 lg:h-32" />
            )}
          </button>
        </div>
      </div>
    </motion.header>
  )
}

export default OrganismHeader
