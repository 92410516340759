import React from 'react'
import { IPageHeaderRecord } from '@/lib/global.types'
import { Image as DatoImage } from 'react-datocms'
import { AtomButton } from '@/components/atoms/AtomButton'
import { motion, Variants } from 'framer-motion'
import { headerVariants, textVariants } from '@/components/helpers/Animations'

const OrganismPageHeader = (props: IPageHeaderRecord) => {
  const headerClasses = `${
    props.image
      ? 'pt-[88px] sm:pb-48 sm:pt-[80px] md:pt-96 md:pb-64 lg:pt-[112px] xl:pt-[128px] 2xl:pt-[144px]'
      : 'h-[240px] sm:h-[280px] md:h-[360px] lg:h-[420px] xl:h-[480px] 2xl:h-[500px]'
  }`

  return (
    <section className={`relative custom-gradient text-primary-light ${headerClasses}`}>
      <div
        className={`container mx-auto px-24 flex flex-col justify-center h-full sm:flex-row sm:justify-between gap-28 md:gap-32 xl:gap-64 2xl:gap-120 ${
          props.image ? 'offset-background' : ''
        }`}
      >
        <div className="flex flex-col justify-center sm:w-3/5 lg:w-2/5">
          <div className="space-y-8">
            <motion.h2
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.8 }}
              className={`header-1 ${props.image ? '' : 'mt-56 md:mt-80 lg:mt-96 xl:mt-[104px] 2xl:mt-[100px]'}`}
            >
              <motion.span className="block" variants={headerVariants}>
                {props.header}
              </motion.span>
            </motion.h2>
            {props.description && (
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
                className="text-1 text-secondary-light overflow-hidden"
              >
                <motion.span className="block" variants={textVariants}>
                  {props.description}
                </motion.span>
              </motion.p>
            )}
          </div>
          {props.link && props.link[0]?.page && (
            <div className="mt-16 md:mt-24 xl:mt-32">
              <AtomButton
                title={props.link[0].page.title}
                href={`/${props.link[0].page.slug}`}
                label={props.link[0].label}
              />
            </div>
          )}
        </div>
        {props.image && (
          <div className="aspect-video sm:aspect-square sm:translate-y-48 md:translate-y-64 z-10 rounded-xl overflow-hidden">
            <DatoImage
              objectFit="cover"
              objectPosition="50% 10%"
              className="h-full w-full"
              data={{ ...props.image.responsiveImage, alt: props.image.alt }}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default OrganismPageHeader
