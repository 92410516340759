import React from 'react'
import { IProcessSectionAboutRecord } from '@/lib/global.types'
import { headerVariants, listItemVariants } from '@/components/helpers/Animations'
import { motion } from 'framer-motion'

const OrganismProcessAboutSection = (props: IProcessSectionAboutRecord) => {
  return (
    <section className="section-space-base section-padding-base custom-gradient text-primary-light">
      <div className="container mx-auto px-24 flex flex-col md:flex-row md:space-x-40 lg:space-x-64 xl:space-x-96 2xl:space-[104px]">
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="header-2 header-space-base"
        >
          <motion.span className="block" variants={headerVariants}>
            {props.title}
          </motion.span>
        </motion.h2>
        <ul className="grid grid-cols-2 gap-24 md:gap-32 lg:gap-56 xl:gap-64 2xl:gap-72">
          {props.processItems.map((item, index) => (
            <motion.li initial="hidden" whileInView="visible" viewport={{ once: true }} key={item.id}>
              <motion.div variants={listItemVariants} custom={index}>
                <span className="hidden md:block text-primary text-3 font-bold underline mb-16 xl:mb-24">
                  {item.label}
                </span>
                <h3 className="header-3 mb-8">{item.title}</h3>
                <p className="text-2 text-neutral-dark">{item.description}</p>
              </motion.div>
            </motion.li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default OrganismProcessAboutSection
