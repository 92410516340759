import React from 'react'
import ReactMarkdown from 'react-markdown'

interface IMarkdownProps {
  markdown: string
  textPosition?: string
}

const defaultProps: IMarkdownProps = {
  markdown: '',
}

const Markdown = ({ markdown }: IMarkdownProps) => {
  const components = {
    p({ children }: any) {
      return <p className="text-2 text-secondary">{children}</p>
    },
    a({ href, children }: any) {
      return (
        <a href={href} title={children} className="underline text-primary underline-offset-1">
          {children}
        </a>
      )
    },
  }

  return (
    <div className="space-y-16">
      <ReactMarkdown components={components}>{markdown}</ReactMarkdown>
    </div>
  )
}

Markdown.defaultProps = defaultProps

export default Markdown
